import {request} from 'network/request'

export function postProductData(parameter) {
  return request({
    url: '/product',
    method: 'post',
    data: parameter
  })
}

export function putProductData(parameter) {
  return request({
    url: '/product',
    method: 'put',
    data: parameter
  })
}

export function getProductDataAll(parameter) {
  return request({
    url: '/product',
    method: 'get',
    data: parameter
  })
}

export function getProductDataOne(parameter) {
  return request({
    url: '/product/' + parameter.id,
    method: 'get',
    data: parameter
  })
}

export function deleteProduct(parameter) {
  return request({
    url: '/product/' + parameter.id,
    method: 'delete',
    data: parameter
  })
}

export function searchProductData(parameter) {
  return request({
    url: '/SearchProductData',
    method: 'post',
    data: parameter
  })
}
